.footer_container {
  height: 100%;
  display: flex;
  flex-direction: column !important;
  width: 100%;
  background-color: #004472;
}

.footer_container_top {
  margin: 4% 4% 0 4%;
  padding-bottom: 40px;
  height: 85%;
  border-bottom: 1px solid #d8eaf5;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}

.footer_container_socialmedia {
  width: 100%;
  gap: 10px;
}

.footer_container_socialmedia_text {
  color: #d8eaf5;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer_container_socialmedia_icon {
  cursor: pointer;
  display: flex;
  flex-direction: row !important;
  margin-top: 2%;
  gap: 2%;
}

.footer_container_products_options {
  display: flex;
  flex-direction: row !important;
  gap: 25%;
  width: 60%;
}

.menu_options_head {
  color: #d8eaf5;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.menu_options_head span {
  display: contents;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.menu_option {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-wrap: nowrap;
}

.spacing_between {
  gap: 4.5%;
}
.spacing_between_options {
  flex-direction: column !;
  gap: 20px;
}

.spacing_between_options_column {
  flex-direction: row !important;
  gap: 90px;
}
.footer_container_companylogo img {
  width: 354px;
  height: 65px;
  flex-shrink: 0;
  margin-left: -15px;
}

.socialmedia_icon img {
  width: 43.904px;
  height: 43.904px;
  flex-shrink: 0;
}

.footer_container_bottom {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  height: 15%;
  margin: 0% 4%;
  padding: 25px 0;
}

.footer_container_copyright {
  width: 70%;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  text-wrap: nowrap;
}

.footer_container_copyright_sub {
  width: 55%;
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
  gap: 15%;
}

.sub_tex {
  color: #fff;
  text-wrap: nowrap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

/* Modal Data */

.Modal_container {
  background: #f0f0f0;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  width: 30%;
  flex-direction: column !important;
  box-shadow: 24;
  padding: 1% 2% 3% 2%;
}

.Modal_head {
  display: flex;
  flex-direction: row !important;
  gap: 30px;
  border-bottom: 2px solid #002d62;
  justify-content: space-between;
  padding: 2% 0%;
}

.Modal_title {
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}
.card_title b {
  display: contents;
  font-weight: 600 !important;
}
.Modal_title b {
  display: contents;
}
.modal_close {
  color: #647491;
  display: flex;
  width: 27px;
  height: 27px;
  padding: 8.25px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  cursor: pointer;
}

.Modal_content {
  padding: 6% 0;
  display: flex;
  flex-direction: column !important;
  gap: 50px;
}

.Modal_content_layout {
  display: flex;
  flex-direction: column !important;
  gap: 5px;
}

.Modal_content_info {
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.Modal_content_points {
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.16px;
}
.Modal_content_info b {
  display: contents;
}

/* contact btn */
.bot_content_btn {
  display: inline-flex;
  width: 30%;
  height: 30px;
  padding: 13px 0px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  background: #7cf169;
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}

@media (max-width: 1150px) {
  .footer_container_companylogo img {
    width: 222px;
    height: 50px;
    flex-shrink: 0;
    margin-left: -9px;
  }

  .footer_container_socialmedia_text {
    font-size: 16px;
  }
  .menu_options_head {
    color: #d8eaf5;

    font-size: 16px;
  }
  .menu_options_head span {
    font-size: 16px;
  }
  .menu_option {
    font-size: 17px;
  }
  .footer_container_copyright {
    font-size: 22.44px;
  }
  .sub_tex {
    font-size: 22.44px;
  }
}

@media (max-width: 1020px) {
  .footer_container {
    height: 100%;
    width: 100%;
  }
  .footer_container_companylogo img {
    width: 135.833px;
    height: 25px;
    flex-shrink: 0;
    margin-left: -6px;
  }
  .footer_container_top {
    padding-bottom: 15px;
    margin: 4% 4% 0 4%;
    width: auto;
    height: 70%;
    border-bottom: 1px solid #d8eaf5;
    display: flex;
    flex-direction: column !important;

   
  }
  .footer_container_socialmedia {
    /* margin-top: 4%; */
    /* padding-bottom: 20px; */
    /* border: 1px solid black; */
    /* border-bottom: 1px solid #d8eaf5; */
    width: 100%;
    /* gap: 10%; */
    height: 24%;
  }
  .footer_container_socialmedia_icon img {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
  .footer_container_socialmedia_text {
    font-size: 12px;
    line-height: normal;
  }

  .footer_container_products_options {
    padding-top: 20px;
    height: 54%;
    width: 100%;
    gap: 10%;
    margin-bottom: 10%;
  }

  .menu_options_head {
    color: #d8eaf5;
    font-size: 10px;
  }
  .menu_options_head span {
    font-size: 10px;
  }

  .menu_option {
    font-size: 10px;
  }
  .spacing_between {
    gap: 22px;
  }

  .spacing_between_options {
    flex-direction: column !;
    gap: 15px;
  }

  .spacing_between_options_column {
    flex-direction: row !important;
    gap: 60px;
  }

  .footer_container_bottom {
    /* margin-top: 5%; */
    padding: 10px 0;
    width: auto;
    display: flex;
    flex-direction: column !important;
    justify-content: none;
    text-align: left;
    gap: 5%;
  }
  .footer_container_copyright {
    width: 100%;
    display: flex;
    justify-content: left;
    text-align: left;
  }
  .footer_container_copyright_sub {
    width: 100%;
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    margin: 10px;
  }
  .footer_container_bottom {
    align-items: center;
    height: 10%;
    margin: 4% 4%;
  }
  .footer_container_copyright {
    font-size: 10px;
  }
  .footer_container_copyright_sub {
    gap: 8px;
  }
  .sub_tex {
    font-size: 10px;
  }

  /* Card----- */

  .Modal_container {
    background: #f0f0f0;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    display: flex;
    width: 85%;
    flex-direction: column !important;
    box-shadow: 24;
    padding: 1% 2% 3% 2%;
  }

  .Modal_head {
    display: flex;
    flex-direction: row !important;
    gap: 30px;
    border-bottom: 2px solid #002d62;
    justify-content: space-between;
    padding: 2% 4%;
  }

  .Modal_title {
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: capitalize;
  }

  .Modal_title b {
    display: contents;
  }
  .modal_close {
    color: #647491;
    display: flex;
    width: 15px;
    height: 15px;
    padding: 8.25px;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    cursor: pointer;
  }

  .Modal_content {
    padding: 6% 0;
    display: flex;
    flex-direction: column !important;
    gap: 10px;
  }

  .Modal_content_layout {
    display: flex;
    flex-direction: column !important;
    gap: 5px;
    padding: 0 4%;
  }

  .Modal_content_info {
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12px;
  }

  .Modal_content_points {
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.12px;
  }
  .Modal_content_info b {
    display: contents;
  }

  /* contact btn */
  .bot_content_btn {
    display: inline-flex;
    width: 30%;
    height: 30px;
    padding: 4px 15px;
    margin-left: 4%;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    background: #7cf169;
    color: #000;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
  }
}
