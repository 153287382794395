.service_container {
  padding: 7% 0% 0% 0%;
  /* background: #f1faff; */
  width: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.service_container_head {
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 137.5% */
  letter-spacing: 0.32px;
  text-transform: capitalize;
}

.service_container_head span {
  display: contents;
  color: #3575c1;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: 0.32px;
  text-transform: capitalize;
}
.service_container_tabs {
  width: 75%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  gap: 50px;
}


.tabs {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
}

.tabs_options {
  width: 25%;
  padding: 1.5% 0%;
  align-items: center;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}

/* Tab Content */
.tab_Content {
  width: 100%;
}

.Service_Content {
  width: 100%;
}
.Service_Content_poster {
  width: 70%;
}
.Service_Content_side {
  width: 30%;
}

.Service_Content_side_content {
  display: flex;
  flex-direction: column;
  gap: 60px;
  background: #f1faff;
  padding: 0% 4% 20% 0%;
}
.Service_Content_side_content2 {
  display: flex;
  flex-direction: column;
  gap: 60px;
  background: #f1faff;
  padding: 18% 0% 18% 3%;
}

.side_content {
  padding: 10% 2% 2% 6%;

  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  text-wrap: wrap !important;
}

@media (max-width: 1600px) {
  .Service_Content_side_content {
    display: flex;
    flex-direction: column;
    gap: 35px;
    background: #f1faff;
    padding: 13% 2% 13% 4%;
  }
  .Service_Content_side_content2 {
    gap: 35px;
  }
  .side_content {
    padding: 10% 2% 2% 6%;
  
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    text-wrap: wrap !important;
  }
}
@media (max-width: 1400px) {
  .Service_Content_side_content {
    gap: 30px;
    background: #f1faff;
    padding: 0% 4% 15% 0%;
  }
  .Service_Content_side_content2 {
    gap: 20px;
  }
  .side_content {
    padding: 10% 2% 2% 6%;
  
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    text-wrap: wrap !important;
  }
}

@media (max-width: 1400px) {
  .Service_Content_side_content {
    gap: 15px;
    background: #f1faff;
    padding: 0% 4% 10% 0%;
  }
  .Service_Content_side_content2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #f1faff;
    padding: 0% 4% 5% 0%;
  }
  .side_content {
    padding: 10% 2% 2% 6%;
  
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    text-wrap: wrap !important;
  }
}

@media (max-width: 800px) {
  .service_container {
    padding: 12% 0% 0% 0%;
    /* background: #f1faff; */
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }
  .service_container_head {
    color: #000;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: capitalize;
  }
  .service_container_head span {
    color: #3575c1;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: capitalize;
  }
  .service_container_tabs {
    width: 92%;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    gap: 20px;
  }
  .tabs {
    width: 92%;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
  }
  .tabs_options {
    width: fit-content;
    padding: 1.5% 0%;
    font-size: 12px;
    letter-spacing: 0.12px;
    border-bottom: none;
  }
  .tab_Content {
    width: 100%;
  }

  .Service_Content {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 5% 0;
    background: #f1faff;
  }
  /* .Service_Content_poster {
    width: 0%;
  } */
  .Service_Content_side {
    width: 90%;
  }

  .Service_Content_side_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    gap: 10px;
    background: #f1faff;
    padding: 0px;
  }
  .Service_Content_side_content2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 15px;
    background: #f1faff;
    padding: 0% 4% 10% 0%;
  }
  .side_content_btn {
    padding: 0% 6%;
    text-decoration: underline;
    color: #002d62;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}

.side_content {
  padding: 30px 0px 0px 0px;
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Manrope, sans-serif;
  font-size: 16px ;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  text-wrap: wrap;
}

.side_content_btn {
  padding: 0;
  color: #002d62;
  font-family: Manrope, sans-serif;
  text-decoration: underline;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  cursor: pointer;
}
/* modal read More Content */

.Modal_container {
  background: #f0f0f0;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  width: 50%;
  flex-direction: column !important;
  box-shadow: 24;
  padding: 1% 2% 3% 2%;
}

.Modal_head {
  display: flex;
  flex-direction: row !important;
  gap: 30px;
  border-bottom: 2px solid #002d62;
  justify-content: space-between;
  padding: 2% 0%;
}

.Modal_title {
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}
.modal_close {
  color: #647491;
  display: flex;
  width: 27px;
  height: 27px;
  padding: 8.25px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  cursor: pointer;
}

.Modal_content {
  padding: 6% 0;
  display: flex;
  flex-direction: column !important;
  gap: 50px;
}

.Modal_content_layout {
  display: flex;
  flex-direction: column !important;
  gap: 20px;
}

.Modal_content_info {
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}

.ideation_content_points {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: flex-start;
}

.ideation_points {
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}
.ideation_points span {
  display: contents;
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.16px;
}

/* contact btn */
.bot_content_btn {
  display: inline-flex;
  width: fit-content;
  height: auto;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  background: #7cf169;
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}

/* repsonsive for modal */

@media (max-width: 800px) {
  .Modal_container {
    background: #f0f0f0;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    display: flex;
    width: 82%;
    padding: 3% 5% 10% 5%;
  }

  .Modal_head {
    display: flex;
    flex-direction: row !important;
    gap: 20px;
    border-bottom: 2px solid #002d62;
    justify-content: space-between;
    padding: 2% 0%;
  }

  .Modal_title {
    font-size: 12px;
  }
  .modal_close {
    width: 15px;
    height: 15px;
  }

  .Modal_content {
    padding: 6% 0;
    display: flex;
    flex-direction: column !important;
    gap: 20px;
  }

  .Modal_content_layout {
    display: flex;
    flex-direction: column !important;
    gap: 15px;
  }
  .side_content_btn {
    padding: 0;
    color: #002d62;
    font-family: Manrope, sans-serif;
    text-decoration: underline;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    cursor: pointer;
  }
  .Modal_content_info {
    font-size: 12px;
  }

  .ideation_content_points {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .ideation_points {
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
  }
  .ideation_points span {
    display: contents;
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.16px;
  }
  

  /* contact btn */
  .bot_content_btn {
    display: inline-flex;
    width: fit-content;
    height: auto;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-size: 12px;
  }
}
