.ContactUsModal_container {
  background: #f2ffed;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  width: 65%;
  flex-direction: column;
  box-shadow: 24;
}
.modal_close {
  color: #647491;
  display: flex;
  width: 27px;
  height: 27px;
  padding: 8.25px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 15px;
  top: 14px;
  border-radius: 14px;
  cursor: pointer;
}

.contact_us_section_body {
  height: auto;
  display: flex;
  flex-direction: row !important;
  background: #f2ffed;
}
.contact_us_section_content {
  padding: 8% 4%;
  width: 30%;
  display: flex;
  flex-direction: column !important;
  gap: 35px;
}
.contact_us_section_content_title {
  width: 100%;

  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  text-transform: capitalize;
}
.contact_us_section_content_title span {
  color: #3575c1;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 125% */
  text-transform: capitalize;
}

.contact_us_section_content_sub {
  color: #000;
  width: 70%;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}


.contact_us_icon{
  width: 100%;
  /* display: flex !important; */
  /* flex-direction: row !important; */
  justify-content: flex-start;
  gap:30px; 
}

.contact_us_phone_icon{
  padding: 10px 0;
  width: 100%;
  /* display: flex !important; */
  flex-direction: row !important;
  justify-content: center;
  gap:15px;
  cursor: pointer;
}
.contact_us_icon_lay{
width: 35px;
height: 35px;
  background-color: #ffffff;
border-radius: 50%; 
display: flex;
justify-content: center;
align-items: center;

}
.contact_us_phone_lay{
  width: 22px;
height: 22px;
  background-color: #ffffff;
border-radius: 50%; 
display: flex;
justify-content: center;
align-items: center;
border: 1px solid #7cf169;
cursor: pointer;
}

.contact_us_icon_lay img{
  width: 30px;
  height: 30px;


  }
  .contact_us_phone_lay img{
    width: 18px;
    height: 18px;
    }

.contact_us_section_form {
  width: 70%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column !important;
  gap: 46px;
  padding: 4% 0%;
}

.contactus_form_container_form {
  gap: 25px;
  margin: 0% 4%;
  display: flex;
  flex-direction: column;
  width: 90%;
}

.form_field_label {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.form_field_sublabel {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 50% !important;
}

.form_field_label_text {
  color: #878787;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
}

.name_input {
  border: 1px solid var(--Borders-Light-Grey, #90909098);
  border-radius: 0;
  background: #f0f0f0;
  display: flex;
  height: 60px;
  padding: 0 20px;
  align-items: center;
  gap: var(--Spacing-8px, 8px);
  align-self: stretch;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.name_input:focus {
  border: 1px solid var(--Borders-Dark-Grey, #999999);
}

.name_input::placeholder {
  color: #878787;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
}

.select_input {
  /* border: 1px solid var(--Borders-Light-Grey, #f0f0f0); */
  background: #f0f0f0;
  display: flex;
  height: 60px;
  padding: 0 20px;
  align-items: center;
  gap: var(--Spacing-8px, 8px);
  align-self: stretch;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.select_input:focus {
  border: 1px solid var(--Borders-Dark-Grey, #999999);
}

.select_input::placeholder {
  color: #878787;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
}
.requiremnt {
  border-radius: 0;
  display: flex;
  justify-content: flex-start;
  border: 1px solid var(--Borders-Light-Grey, #90909098);
  background: var(--Icons-White, #f0f0f0);
  display: flex;
  height: 200px;
  padding: 4% 4%;
  align-items: left;
  gap: var(--Spacing-8px, 8px);
  font-size: 28px;
  font-family: "Inter", sans-serif;
}

.requiremnt::placeholder {
  color: #878787;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
}
.error {
  color: red;
  font-size: 14px;
  margin-top: 4px;
}
.contact_us_section_form_btn {
  margin: 0% 4%;
  padding: 10px 30px;
  height: auto;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7cf169;
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  border: none;
}
@media (max-width: 800px) {
  .ContactUsModal_container {
    width: 90%;
  }
  .modal_close {
    color: #647491;
    display: flex;
    width: 17px;
    height: 17px;
    padding: 8.25px;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 85%;
    top: 14px;
    border-radius: 14px;
    cursor: pointer;
  }

  .contact_us_section_body {
    flex-direction: column !important;
  }
  .contact_us_section_content {
    padding: 25px 0px;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    gap: 5px;
  }
  .contact_us_section_content_title {
    width: 100%;
    font-size: 16px;
    align-items: center;
    line-height: 22px;
  }
  .contact_us_section_content_title span {
    font-size: 16px;
    line-height: 22px; /* 125% */
  }

  .contact_us_section_content_sub {
    color: #000;
    width: 100%;
    align-items: center;
    font-size: 10px;
  }

  .contact_us_section_form {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column !important;
    gap: 16px;
    padding: 4% 0%;
  }

  .contactus_form_container_form {
    gap: 16px;
    margin: 0% 4%;
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .form_field_label {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  .form_field_sublabel {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 50% !important;
  }

  .form_field_label_text {
    font-family: Inter, sans-serif;
    font-size: 10px;
  }

  .name_input {
    border: 1px solid var(--Borders-Light-Grey, #90909098);
    background: #f0f0f0;
    display: flex;
    height: 30px;
    padding: 0 20px;
    align-items: center;
    gap: var(--Spacing-8px, 8px);
    align-self: stretch;
    font-size: 10px;
    font-family: "Inter", sans-serif;
  }
  .name_input:focus {
    border: 1px solid var(--Borders-Dark-Grey, #999999);
  }

  .name_input::placeholder {
    color: #878787;
    font-family: Manrope, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.18px;
    text-transform: capitalize;
  }
  .select_input {
    /* border: 1px solid var(--Borders-Light-Grey, #90909098); */
    background: #f0f0f0;
    display: flex;
    height: 28px;
    /* padding: 0 20px; */
    align-items: left;
    /* gap: var(--Spacing-8px, 8px); */
    align-self: stretch;
    font-size: 18px;
    font-family: "Inter", sans-serif;
  }
  .select_input::placeholder {
    color: #878787;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .requiremnt {
    display: flex;
    justify-content: flex-start;
    border: 1px solid var(--Borders-Light-Grey, #90909098);
    background: var(--Icons-White, #f0f0f0);
    display: flex;
    height: 150px;
    padding: 4% 4%;
    align-items: left;
    gap: var(--Spacing-8px, 8px);
    font-size: 10px;
    font-family: "Inter", sans-serif;
  }

  .requiremnt::placeholder {
    font-size: 10px;
  }
  .error {
    color: red;
    font-size: 8px;
    margin-top: 4px;
  }
  .contact_us_section_form_btn {
    margin: 0% 4%;
    padding: 2% 0%;
    width: 30%;
    font-size: 10px;
  }
}

@media (max-width:400px){
  .form_field_sublabel {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 48% !important;
  }
}