* {
  font-family: Inter, sans-serif;
}
.navbar_container {
  /* width: 100vw; */
  height: auto;
  padding: 20px 0;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  background: transparent;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out;
  box-shadow: 1px 1px 10px #d1d1d1;
}

.navbar_scrolled {
  background-color: white;
}

.navbar_logo_container {
  width: 22%;
  align-items: center;
}
.navbar_logo {
  width: 205px;
  height: 37px;
  flex-shrink: 0;
  cursor: pointer;
}
.navbar_options {
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
  width: 50%;

  align-items: center;
  /* gap: 32px; */
}
.navbar_options_text {
  text-wrap: nowrap;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  position: relative;
}
.drawer_options_text {
  text-wrap: nowrap;
  color: #000;
  font-family: Inter;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  position: relative;
}

.navbar_notmal_option {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: underline;
  cursor: pointer;
}

.navbar_options_text::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #000; /* Adjust color for the underline */
  transition: width 0.3s ease-in-out;
}

.navbar_options_text:hover::after {
  width: 100%;
}
.navbar_btn_container {
  /* display: none; */
  width: 22%;
  align-items: center;
}
.navbar_btn {
  display: inline-flex;
  height: auto;
  width: fit-content;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #7cf169;
  color: #222;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
/* .navbar_btn:hover {
  color: #222;
  border: 2px solid #7cf169;
  background-color: transparent;
} */

@media (max-width: 1183px) {
  .navbar_container {
    display: flex;
    justify-content: space-around;
  }
  .navbar_btn_container {
    display: none;
  }

  .navbar_options {
    display: none;
  }

  .navbar_logo_container {
    display: flex;
    justify-content: left;

    /* width: 30%; */
    align-items: left;
  }

  .menubar_btn {
    width: 10%;
  }

  .navbar_notmal_option {
    font-size: 13px;
  }
  .navbar_options_text {
    font-size: 13px;
  }
  .navbar_menu_button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #222;
    font-size: 2rem;
  }

  .drawer {
    width: 30vw;
    height: 30vh;
  }
  .drawer_options {
    margin-top: 15%;
    width: 30vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  }
  .drawer_head {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    margin: 15px 45px;
  }
}

@media (max-width: 1228px) {
  .navbar_notmal_option {
    font-size: 16px;
  }
  .navbar_options_text {
    font-size: 16px;
  }
}

@media (max-width: 1184px) {
  .navbar_options {
    display: none;
    gap: 10px;
  }

  .navbar_notmal_option {
    font-size: 12px;
  }
  .navbar_options_text {
    font-size: 12px;
  }

  .navbar_btn {
    display: inline-flex;
    height: 25px;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
}

@media (max-width: 1100px) {
  .navbar_options {
    display: none;
    gap: 10px;
  }

  .navbar_options_text {
    font-size: 12px;
  }

  .navbar_btn {
    display: inline-flex;
    height: 25px;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
}

@media (max-width: 800px) {
  .navbar_container {
    width: 100%;
    display: flex;
    flex-direction: row !important;
    justify-content: space-around;
    background: transparent;
    box-shadow: 1px 1px 12px #d1d1d1;

    /* align-items: center; */
  }
  .navbar_logo {
    width: 136px;
    height: 24px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .navbar_logo_container {
    width: 50%;
    align-items: center;
    display: flex;

    align-items: flex-start;
  }
  .menubar_btn {
    width: 30%;
    text-align: left;
    display: flex;

    align-items: flex-end;
  }
  .iconbutton {
    width: 35%;
    text-align: left;
  }
  .drawer_options {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 37vw;
    position: absolute;
    top: 1%;
    right: 0;
    padding: 0% 0% 0 6%;
    height: auto;
  }
  .drawer_head {
    position: absolute;
    top: 3%;
    right: 0;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    margin: 0px;
  }

  .drawer_options_text {
    border-bottom: 2px solid #fff;
    padding: 10% 0;

    text-align: left;
    color: #002d62;
    font-family: Manrope, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .drawer_options_text span {
    display: contents;
    color: #002d62;
    font-family: Manrope, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
  }
}
