* {
  font-family: Inter, sans-serif;
}

.privacy_container {
  display: "flex";
  flex-direction: column !important;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.privacy_container_head {
  width: 100%;
  background: #f0f0f0;
  color:#3575c1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 140px 0;
  height: auto;
  font-size: 42px;
  font-weight: 600;
}

  
  .privacy_container_content {
    padding: 50px 10%;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .privacy_container_content h6 {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  @media (max-width: 800px) {
    .privacy_container_head {
        
        width: 100%;
        background: #f0f0f0;
        color:#3575c1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 50px 0%;
        height: auto;
        font-size: 32px;
        font-weight: 600;
      }
      
      .privacy_container_head span{
        padding: 0px 6%;
      }

      .privacy_container_content {
        padding: 30px 6%;
        font-size: 16px;
        line-height: 1.6;
      }
      
      .privacy_container_content h5 {
        font-size: 16px;
        margin-top: 7px;
        margin-bottom: 5px;
        font-weight: 600;
      }
      
      .body_content {
        font-size: 12px;
      }

   
  }