.formulation_section_container {
  display: flex;
  flex-direction: column !important;
  width: 100%;
  height: auto;
  gap: 100px;
  padding: 7% 0%;
  justify-content: center;
  align-items: center;
}

/* Part 1  Steps */
.formulation_section_part1 {
  background: #f0f0f0;
  display: flex;
  flex-direction: row !important;

  justify-content: center;
  /* padding: 4% 0%; */
  width: 100%;
}

.formulation_section_part1_img {
  width: 60%;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
}

/* End------- */

/* Part 2 ----- */

/* End----- */

/* Part 3 Content  and contact btn*/

.formulation_section_part3 {
  width: 100%;
  height: auto;
  gap: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formulation_section_part3_content {
  width: 100%;
  height: auto;
  gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formulation_section_part3_head {
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 87.5% */
  letter-spacing: 0.32px;
}
.formulation_section_part3_head_color {
  color: #3575c1;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0.32px;
  display: contents;
}

.formulation_section_part3_info {
  width: 55%;
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.18px;
}
.formulation_section_part3_img {
  display: flex;
  width: 80%;
  justify-content: center;
  flex-direction: row !important;
  gap: 15%;
}

.formulation_section_part3_content_contact_btn {
  display: inline-flex;
  height: auto;
  padding: 10px 30px;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  background: #7cf169;
  cursor: pointer;
}

.formulation_section_part3_content_contact_btn span {
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

/* Poster Section */

.formulation_section_part1 {
  width: 100%;
}

.left {
  /* padding: 4% 0%; */
  width: 40%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding: 72px;
}

.left_title {
  width: 80%;

  text-align: left;
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
  text-transform: capitalize;
}
.left_title span {
  color: #3575c1;
  font-family: Manrope, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  text-transform: capitalize;
}

.left_accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: auto;
}

.right_video {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right_video video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* ------ */

/* Accordion */

.accordion_layout {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.accordion__section {
  width: 80%;
  display: flex;
  flex-direction: column !important;
  transition: background-color 0.6s ease;
  padding: 2% 0 0 0%;

  border-top: 1px solid #004472;
}

.accordion {
  width: 100%;
  color: #444;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
}

.accordion__title {
  display: flex;
  flex-direction: row !important;
  width: 100%;
}

.accordion__title_step {
  display: flex;
  flex-direction: row;

  /* height: 10px; */
  justify-content: center;
  align-items: center;
  border-right: 1px solid #fff;
  padding: 0% 2%;
}

.accordion__title_step_text {
  transform: rotate(-90deg);
  height: 20%;
  position: relative;
  top: 50px;
  right: 40px;
  color: #fff;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.accordion__title_step_no {
  color: #fff;
  font-family: "Playfair Display";
  font-size: 72px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

.accordion__title_tt {
  display: flex;
  justify-content: center;
  padding: 2% 4%;
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}
.accordion__title_tt span {
  display: contents;
  color: #222;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}
.accordion_info {
  display: flex;
  align-items: flex-end;
  gap: var(--8px, 8px);
  align-self: stretch;
  padding: 12px 0 0px 0px;
  margin-left: 38px;
  width: 70%;
}
.accordion__section_down img {
  width: 20%;
  width: 16px;
  height: 16px;
}

.accordion__icon {
  stroke-width: 4.148px;
  stroke: #000;
  width: 0px;
  height: 4.148px;
  flex-shrink: 0;
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion__content {
  overflow: hidden;
  transition: max-height 0.6s ease;
  /* line-height: 150%; */
  /* padding: 30px 0px 0px 0px; */
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
}

.accordion__text {
  padding: 0% 0 4% 4%;
  color: #000;
  width: 50%;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
}

.accordion_contact_btn {
  margin-top: 30px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #7cf169;
  color: #004472;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.accordion_contact_btn:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* ---end ----- */
@media (max-width: 800px) {
  /* Video Content */
  .formulation_section_container {
    display: flex;
    flex-direction: column !important;
    width: 100%;
    height: auto;
    gap: 45px;
    padding: 12% 0%;
    justify-content: center;
    align-items: center;
  }
  .formulation_section_part1 {
    background: linear-gradient(to bottom, #f2ffed 35%, #ffffff 30%);
    display: flex;
    flex-direction: row !important;

    justify-content: center;
    /* padding: 4% 0%; */
    width: 100%;
  }

  .formulation_section_part1 {
    width: 100%;
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
  }

  .left {
    padding: 4% 0%;
    background: #f0f0f0;
    width: 84%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }

  .left_title {
    padding: 4% 0%;
    width: 80%;
    text-align: left;
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    line-height: 20px; /* 125% */
  }
  .left_title span {
    color: #3575c1;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
    text-transform: capitalize;
    display: contents;
  }

  .left_accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .right {
    width: 84%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: auto;
  }

  .right_video {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right_video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Accordion */

  .accordion_layout {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .accordion__section {
    width: 85%;
    display: flex;
    flex-direction: column !important;
    transition: background-color 0.6s ease;
    /* padding: 2% 0 0 0%; */

    border-top: 1px solid #004472;
  }

  .accordion {
    width: 100%;
    color: #444;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
  }

  .accordion__title {
    display: flex;
    flex-direction: row !important;
    width: 100%;
  }

  .accordion__title_step {
    display: flex;
    flex-direction: row;

    /* height: 10px; */
    justify-content: center;
    align-items: center;
    border-right: 1px solid #fff;
    padding: 0% 2%;
  }

  .accordion__title_step_text {
    transform: rotate(-90deg);
    height: 20%;
    position: relative;
    top: 30px;
    right: 20px;
    color: #fff;
    font-family: Manrope;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .accordion__title_step_no {
    color: #fff;
    font-family: "Playfair Display";
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
  }

  .accordion__title_tt {
    display: flex;
    justify-content: center;
    padding: 0% 4%;
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }
  .accordion__title_tt span {
    display: contents;
    color: #222;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }
  .accordion_info {
    display: flex;
    align-items: flex-end;
    gap: var(--8px, 8px);
    align-self: stretch;
    padding: 12px 0 0px 0px;
    margin-left: 38px;
    width: 70%;
  }
  .accordion__section_down img {
    width: 20%;
    width: 9.575px;
    height: 9.575px;
  }

  .accordion__icon {
    stroke-width: 4.148px;
    stroke: #000;
    width: 0px;
    height: 4.148px;
    flex-shrink: 0;
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  .rotate {
    transform: rotate(90deg);
  }

  .accordion__content {
    overflow: hidden;
    transition: max-height 0.6s ease;
    /* line-height: 150%; */
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: row !important;
    justify-content: space-evenly;
  }

  .accordion__text {
    padding: 0% 0 5% 5%;
    color: #000;
    width: 50%;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
  }

  .accordion_contact_btn {
    margin-top: 15px;
    width: 25.135px;
    height: 23.938px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 12px;
    background-color: #7cf169;
    color: #004472;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  /* Summary */
  .formulation_section_part1_img {
    width: 85%;
  }

  .formulation_section_part3_content {
    gap: 20px;
    width:84%;
    align-items: flex-start;
  }
  .formulation_section_part3 {
    gap: 30px;
  }

  .formulation_section_part3_head {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px; /* 175% */
    letter-spacing: 0.16px;
    text-align: left;
  }
  .formulation_section_part3_head_color {
    font-size: 16px;
    font-weight: 700;
    line-height: 28px; /* 175% */
    letter-spacing: 0.16px;
  }
  .formulation_section_part3_info {
    width: 100%;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    letter-spacing: 0.12px;
    text-align: left;
  }
  .formulation_section_part3_img {
    width: 70%;
    gap: 15%;
  }
  .part3_img img {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }

  .formulation_section_part3_content_contact_btn {
    height: auto;
    padding: 10px 20px;
    gap: 10px;
    width: fit-content;
  }
  .formulation_section_part3_content_contact_btn span {
    font-size: 12px;
  }
}
