.vision_container {
  padding: 7% 0 0 0%;
  width: 100%;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  gap: 50px;
}

.vision_container_title {
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 137.5% */
  letter-spacing: 0.32px;
  text-transform: capitalize;
}

.vision_container_title span {
  display: contents;
  color: #3575c1;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: 0.32px;
  text-transform: capitalize;
}

.vision_container_content {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  width: 80%;
}

.left_content {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.poster_name {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.poster_name_text {
  position: absolute;
  top: -80px;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: flex-end;
  align-items: center;
}

.poster_name_text span {
  display: contents;
  color: #fff;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.right_content {
  background: #f0f0f0;
  padding: 3% 0%;
  width: 50%;
  /* height: 50vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  width: 80%;
  justify-content: center;
  align-items: center;
  transition: border-left 0.3s ease, opacity 0.3s ease;
}
.single_content {
  display: flex;
  flex-direction: column !important;
  gap: 5px;
  width: 80%;
  padding: 4% 4%;
  cursor: pointer;
}
.content_title {
  text-align: left;
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}
.content_title span {
  display: contents;
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

.content_info {
  text-align: left;
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}

@media (max-width: 850px) {
  .vision_container {
    padding: 12% 0 0 0%;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
    gap: 20px;
  }
  .vision_container_title {
    color: #000;
    padding: 0px 0px 0px 30px;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: capitalize;
  }
  .vision_container_title span {
    color: #3575c1;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: capitalize;
  }

  .vision_container_content {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }

  .left_content {
    width: 100%;
    height: 35vh;
  }

  .right_content {
    background: #f0f0f0;
    padding: 4% 0%;
    width: 100%;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content {
    width: 85%;
    justify-content: center;
    align-items: center;
    transition: border-left 0.3s ease, opacity 0.3s ease;
  }
  .single_content {
    display: flex;
    flex-direction: column !important;
    gap: 1px;
    width: 85%;
    padding: 4% 0%;
    cursor: pointer;
  }
  .content_title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
  }
  .content_title span {
    display: contents;
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }

  .content_info {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}
