.contact_us_section_container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7% 0%;
}

.contact_us_section_body {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: row !important;
  padding: 4% 6%;
  background: #f2ffed;
  gap: 8%;
}
.contact_us_section_content {
  margin-top: 5%;
  width: 30%;
  display: flex;
  flex-direction: column !important;
  gap: 35px;
}
.contact_us_section_content_title {
  width: 85%;
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  text-transform: capitalize;
}
.contact_us_section_content_title span {
  color: #3575c1;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 125% */
  text-transform: capitalize;
  display: block;
}

.contact_us_section_content_sub {
  color: #000;
  width: 85%;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}

.contact_us_section_form {
  width: 70%;
  /* height: auto; */
  background-color: #ffffff;
  display: flex;
  flex-direction: column !important;
  gap: 46px;
  padding: 4% 0%;
}

.contactus_form_container_form {
  gap: 36px;
  margin: 0% 4%;
  display: flex;
  flex-direction: column;
  width: 90%;
}

.form_field_label {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.form_field_sublabel {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 50% !important;
}

.form_field_label_text {
  color: #878787;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
}

.name_input {
  border: 1px solid var(--Borders-Light-Grey, #90909098);
  border-radius: 0;
  background: #f0f0f0;
  display: flex;
  height: 60px;
  padding: 0 20px;
  align-items: center;
  gap: var(--Spacing-8px, 8px);
  align-self: stretch;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.name_input:focus {
  /* border: 1px solid var(--Borders-Dark-Grey, #949494); */
}
.error {
  color: red;
  font-size: 14px;
  margin-top: 4px;
}
.name_input::placeholder {
  color: #878787;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
}
.select_input {
  /* border: 1px solid var(--Borders-Light-Grey, #f0f0f0); */
  background: #f0f0f0;
  display: flex;
  height: 60px;
  padding: 0 20px;
  align-items: center;
  gap: var(--Spacing-8px, 8px);
  align-self: stretch;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.select_input:focus {
  border: 1px solid var(--Borders-Dark-Grey, #999999);
}

.select_input::placeholder {
  color: #878787;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
}
.requiremnt {
  border-radius: 0;
  display: flex;
  justify-content: flex-start;
  border: 1px solid var(--Borders-Light-Grey, #90909098);
  background: var(--Icons-White, #f0f0f0);
  display: flex;
  height: 200px;
  padding: 4% 4%;
  align-items: left;
  gap: var(--Spacing-8px, 8px);
  font-size: 28px;
  font-family: "Inter", sans-serif;
}

.requiremnt::placeholder {
  color: #878787;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
}

.contact_us_section_form_btn {
  margin: 0% 4%;
  padding: 2% 0%;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7cf169;
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  border: none;
}


/* Icons  */

.contact_us_icon{
  width: 80%;
  /* display: flex !important; */
  /* flex-direction: row !important; */
  align-items: flex-start;
    gap: 30px;
}

.contact_us_phone_icon{
  padding: 10px 0;
  width: 100%;
  /* display: flex !important; */
  flex-direction: row !important;
  justify-content: flex-start;
  gap:15px;
  cursor: pointer;
}
.contact_us_icon_lay{
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex
;
  justify-content: center;
  align-items: center;
  border: 1px solid #7cf169;
  padding: 3px;


}
.contact_us_phone_lay{
  width: 30px;
height: 30px;
  background-color: #ffffff;
border-radius: 50%; 
display: flex;
justify-content: center;
align-items: center;
border: 1px solid #7cf169;
cursor: pointer;
}

.contact_us_icon_lay img{
  width: 30px;
  height: 30px;


  }
  .contact_us_phone_lay img{
    width: 18px;
    height: 18px;
    }
/* Responsive .. */

@media (max-width: 800px) {
  .contact_us_section_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    padding: 12% 0% 6% 0%;
  }
  .contact_us_section_body {
    margin-top: 0px;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column !important;
    padding: 4% 6%;
    gap: 15px;
  }
  .contact_us_section_content {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
  }
  .contact_us_section_content_title {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px; /* 150% */
    letter-spacing: 0.12px;
  }
  .contact_us_section_content_title span {
    color: #3575c1;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: flex-start;
    font-weight: 700;
    line-height: 18px; /* 150% */
    letter-spacing: 0.12px;
    font-size: 16px;
    margin-bottom: 10px;;
  }

  .contact_us_section_content_sub {
    width: 65%;
    display: flex;

    justify-content: center;
    align-items: center;

    font-size: 12px;
  }
  .contact_us_section_form {
    margin-top: 10px;
    width: 100%;
    gap: 16px;
    padding: 4% 0%;
  }
  .contactus_form_container_form {
    gap: 16px;
    margin: 0% 4%;
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .form_field_label {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  .form_field_sublabel {
    width: 48% !important;
  }
  .name_input {
    border: 1px solid var(--Borders-Light-Grey, #90909098);
    background: #f0f0f0;
    display: flex;
    height: 28px;
    padding: 0 10px;
    align-items: center;
    gap: var(--Spacing-8px, 8px);
    align-self: stretch;
    font-size: 10px;
    font-family: "Inter", sans-serif;
  }
  .select_input {
    /* border: 1px solid var(--Borders-Light-Grey, #90909098); */
    background: #f0f0f0;
    display: flex;
    height: 28px;
    /* padding: 0 20px; */
    align-items: left;
    /* gap: var(--Spacing-8px, 8px); */
    align-self: stretch;
    font-size: 18px;
    font-family: "Inter", sans-serif;
  }
  .select_input::placeholder {
    color: #878787;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .name_input::placeholder {
    color: #878787;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .error {
    color: red;
    font-size: 8px;
    margin-top: 4px;
  }
  .requiremnt {
    height: 35.714px;
    flex-shrink: 0;
    padding: 4% 4%;
    align-items: left;
    gap: var(--Spacing-8px, 8px);
    font-size: 8px;
    font-family: "Inter", sans-serif;
    border: 1px solid var(--Borders-Light-Grey, #90909098);
  }

  .requiremnt::placeholder {
    color: #878787;
    padding: 4% 4%;
    font-family: Inter, sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .contact_us_section_form_btn {
    margin: 0% 4%;
    padding: 2% 0%;
    width: 30%;
    justify-content: center;
    font-size: 8px;
  }
}
