.accordion__section {
  width: 100%;
  display: flex;
  flex-direction: column !important;
  transition: background-color 0.6s ease;
  /* padding: 4% 4%; */
  border-bottom: 2px solid #cacaca;
}

.accordion {
  color: #444;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  margin-top: 3.5%;
}

.accordion__title {
  width: 90%;
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 138%; /* 44.16px */
  text-transform: none;
}

.accordion_info {
  display: flex;
  align-items: flex-end;
  gap: var(--8px, 8px);
  align-self: stretch;
  padding: 12px 0 0px 0px;
  margin-left: 38px;
  width: 70%;
}
.accordion__section_down img {
  width: 20px;
  height: 20px;
}

.accordion__icon {
  stroke-width: 4.148px;
  stroke: #000;
  width: 0px;
  height: 4.148px;
  flex-shrink: 0;
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion__content {
  overflow: hidden;
  transition: max-height 0.6s ease;
  /* line-height: 150%; */
  padding: 30px 0px 0px 0px;
}

.accordion__text {
  color: #000;
  width: 80%;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
}

@media (max-width: 1183px) {
  .accordion__title {
    width: 60%;
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 16px;
  }
  .accordion__text {
    color: #000;
    width: 80%;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
  }
}

@media (max-width: 800px) {
  .accordion__title {
    font-size: 12px;
    width: 90%;
  }
  .accordion__content {
    padding: 10px 0px 0px 0px;
  }
  .accordion__text {
    font-size: 10px;
  }
  .accordion__section_down img {
    width: 10px;
    height: 10px;
  }
.accordion__section {
  padding-bottom: 10px ;
  padding-top: 10px;
}
}
