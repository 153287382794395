* {
  margin: 0;
  padding: 0;
}

/* Footer */

.footer_container {
  /* margin-top: 15%; */
  width: 100%;
  height: auto;

  bottom: 0;
}

.nav_container {
  width: 100%;
  z-index: 1000;
  background: white;
  align-items: center;
  position: sticky;
  top: 0;
  transition: background-color 0.3s ease-in-out;
  /* border: 1px solid black; */
}

.whatsapp_container{
  display: flex;
      position: fixed;
      right: 2rem;
      bottom: 4rem;
      z-index: 5;
      background: #fff;
      border-radius: 50%;
      padding: .3rem;
      box-shadow: 0 0 10px -4px;
}

@media (max-width: 1020px) {
  .footer_container {
    margin-top: 2%;

    width: 100%;
    /* height: 65vh; */
    bottom: 0;
  }
}

@media (max-width: 400px) {
  .nav_container {
    width: 100%;
  }
  .footer_container {
    margin-top: 2%;

    width: 100%;
    /* height: 65vh; */
    bottom: 0;
  }
}
