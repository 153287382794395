/* Contact Form Section */

.ContactUs_form_container {
  width: 100%;
  height: auto;
  background: linear-gradient(to bottom, #002d62 70%, #fff 30%);
  padding: 6% 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactUs_form {
  /* border: 1px solid black; */
  padding: 6% 0;
  width: 80%;
  gap: 60px;
  background: #f2ffed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ContactUs_form_content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.ContactUs_form_content_title {
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  text-transform: capitalize;
}
.ContactUs_form_content_title span {
  color: #3575c1;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 125% */
  text-transform: capitalize;
  display: block;
}

.ContactUs_form_content_subtitle {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* contact Form  */

.contact_us_section_form {
  width: 60%;
  /* height: auto; */
  background-color: #ffffff;
  display: flex;
  flex-direction: column !important;
  gap: 46px;
  padding: 4% 0%;
}

.contactus_form_container_form {
  gap: 36px;
  margin: 0% 4%;
  display: flex;
  flex-direction: column;
  width: 90%;
}

.form_field_label {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.form_field_sublabel {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 50% !important;
}

.form_field_label_text {
  color: #878787;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
}

.name_input {
  border: 1px solid var(--Borders-Light-Grey, #90909098);
  border-radius: 0;
  background: #f0f0f0;
  display: flex;
  height: 60px;
  padding: 0px 20px;
  align-items: center;
  gap: var(--Spacing-8px, 8px);
  align-self: stretch;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.name_input:focus {
  border: 1px solid var(--Borders-Dark-Grey, #999999);
}

.name_input::placeholder {
  color: #878787;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
}

.select_input {
  /* border: 1px solid var(--Borders-Light-Grey, #f0f0f0); */
  background: #f0f0f0;
  display: flex;
  height: 60px;
  padding: 0 20px;
  align-items: center;
  gap: var(--Spacing-8px, 8px);
  align-self: stretch;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.select_input:focus {
  border: 1px solid var(--Borders-Dark-Grey, #999999);
}

.select_input::placeholder {
  color: #878787;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
}
.requiremnt {
  display: flex;
  border-radius: 0;
  justify-content: flex-start;
  border: 1px solid var(--Borders-Light-Grey, #90909098);
  background: var(--Icons-White, #f0f0f0);
  display: flex;
  height: 200px;
  padding: 4% 4%;
  align-items: left;
  gap: var(--Spacing-8px, 8px);
  font-size: 28px;
  font-family: "Inter", sans-serif;
}

.requiremnt::placeholder {
  color: #878787;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
}
.error {
  color: red;
  font-size: 14px;
  margin-top: 4px;
}
.contact_us_section_form_btn {
  margin: 0% 4%;
  padding: 10px 30px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7cf169;
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  border: none;
}

/* End */

/* Values Card  */

.ContactUs_socialMedia_container {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  height: 100%;
  justify-content: center;
  /* gap: 36px; */
  margin-bottom: 5%;
}

.ContactUs_values_card_layout {
  /* width: 40%; */
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  gap: 32px;
  align-items: center;
}
.ContactUs_values_card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  height: 30%;
  flex-shrink: 0;
  border-radius: 13.192px;
  background: #d8eaf5;
}

.ContactUs_values_card_img {
  width: 80.323px;
  height: 83px;
  flex-shrink: 0;
}
.ContactUs_values_card_title {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.342px; /* 151.712% */
  text-transform: capitalize;
  width: 70%;
}

.socialmedia_card_info_layout {
  /* width: 40%; */

  display: flex;
  flex-direction: row !important;
  width: 80%;
  justify-content: space-between;
  height: auto;
  /* flex-shrink: 0; */
  gap: 40px;
}

.socialmedia_card {
  background: #f0f0f0;
 width: 32%;
  padding: 2% 2%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.border {
  margin-left: 6%;
  width: 88%;
  height: 2px;
  background: #fff;
}
.socialmedia_card_icon {
  width: 54.08px;
  height: 54.08px;
  flex-shrink: 0;
}

.socialmedia_card_title {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  text-wrap: nowrap;
}

.socialmedia_card_text {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* text-wrap: nowrap; */
}

.socialmedia_card_email {
  color: #000;
  text-wrap: nowrap;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: lowercase;
}
/* ------- */

/* Responsive  */

@media (max-width: 850px) {
  /* Contact Form Section */

  .ContactUs_form_container {
    background: #002d62;
    margin-bottom: 0;
  }

  .ContactUs_form {
    /* border: 1px solid black; */
    padding: 6% 0;
    width: 85%;
    gap: 20px;
  }

  .ContactUs_form_content {
    gap: 10px;
  }

  .ContactUs_form_content_title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  .ContactUs_form_content_title span {
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
    display: block;
  }

  .ContactUs_form_content_subtitle {
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  /* Form  */
  .contact_us_section_form {
    margin-top: 10px;
    width: 80%;
    gap: 16px;
    padding: 4% 0%;
  }
  .contactus_form_container_form {
    gap: 16px;
    margin: 0% 4%;
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .form_field_label {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  .form_field_sublabel {
    width: 48% !important;
  }
  .name_input {
    border: 1px solid var(--Borders-Light-Grey, #90909098);
    background: #f0f0f0;
    display: flex;
    justify-content: center;

    height: 28px;
    padding: 0px 15px;
    align-items: center;
    gap: var(--Spacing-8px, 8px);
    align-self: stretch;
    font-size: 12px;
    font-family: "Inter", sans-serif;
  }
  .name_input::placeholder {
    color: #878787;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .error {
    color: red;
    font-size: 8px;
    margin-top: 4px;
  }
  .select_input {
    /* border: 1px solid var(--Borders-Light-Grey, #90909098); */
    background: #f0f0f0;
    display: flex;
    height: 28px;
    /* padding: 0 20px; */
    align-items: left;
    /* gap: var(--Spacing-8px, 8px); */
    align-self: stretch;
    font-size: 18px;
    font-family: "Inter", sans-serif;
  }
  .select_input::placeholder {
    color: #878787;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .name_input::placeholder {
    color: #878787;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .requiremnt {
    height: 35.714px;
    flex-shrink: 0;
    padding: 4% 4%;
    align-items: left;
    gap: var(--Spacing-8px, 8px);
    font-size: 12px;
    font-family: "Inter", sans-serif;
  }

  .requiremnt::placeholder {
    color: #878787;
    padding: 4% 0%;
    font-family: Inter, sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .contact_us_section_form_btn {
    margin: 0% 4%;
    padding: 10px 20px;
    width: fit-content;
    justify-content: center;
    font-size: 8px;
  }
  /* end... */

  /* Value Card */
  .ContactUs_socialMedia_container {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 36px;
    /* margin-top: 20%; */
  }
  .ContactUs_values_card_layout {
    width: 100%;
    gap: 16px;
  }
  .ContactUs_values_card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    gap: 26px;
    height: 187.385px;
    flex-shrink: 0;
    border-radius: 13.192px;
    background: #d8eaf5;
  }

  .ContactUs_values_card_img {
    width: 80px;
    height: 62.462px;
    flex-shrink: 0;
  }
  .ContactUs_values_card_title {
    font-size: 16px;
    line-height: 23px;
    width: 90%;
  }
  
  .socialmedia_card_info_layout {
    /* width: 40%; */
  
    display: flex;
    flex-direction: column !important;
    width: 100%;
    justify-content: flex-start;
    height: auto;
    /* flex-shrink: 0; */
    background: #f0f0f0;
    gap: 0px;
  }

  .socialmedia_card {
    padding-left: 8%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    gap: 10px;
      background: none;
     width: 80%;
      flex-direction: column;
      text-wrap: wrap;
    
  }
  .socialmedia_card_icon {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }

  .socialmedia_card_title {
    font-size: 16px;
  }

  .socialmedia_card_text {
    font-size: 12px;
    text-wrap: wrap;
  }

  .socialmedia_card_email {
    font-size: 12px;
    text-wrap: wrap;
  }
}
