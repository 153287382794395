.main_container {
  position: relative;
  width: 100%;
}

.home_container {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0%;
  left: 0%;
  overflow: hidden;
  margin: 0;
}

.home_container video {
  opacity: 0.9;
  z-index: -1;
  /* min-height: 100%;
  min-width: 100%; */
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Home Banner Content */

.homepage_banner_content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5%;
  z-index: -1;
}

.homepage_banner_title {
  color: #000;
  color: #fff;
  text-align: center;
  font-family: "Taviraj", serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 110% */
  text-transform: capitalize;
}
.homepage_banner_title span {
  display: contents;
  color: #fff;
  font-family: Taviraj, serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  text-transform: capitalize;
}

.homepage_banner_subtitle {
  color: #fff;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 40%;
}

.homepage_banner_btn {
  height: 7%;
}

.req_btn {
  display: inline-flex;
  height: auto;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-shrink: 0;
  background: #7cf169;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}

/* .req_btn:hover {
  background-color: transparent;
  border: 2px solid #7cf169;
  color: #7cf169;
} */

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.homepage_banner_content {
  opacity: 0;
  animation: slide-up 1.5s ease forwards;
}

.homepage_banner_title {
  opacity: 0;
  animation: slide-up 1.5s ease forwards;
  animation-delay: 0.5s;
}
.homepage_banner_subtitle{
  opacity: 0;
  animation: slide-up 1.5s ease forwards;
  animation-delay: 0.7s;
}

.homepage_banner_btn {
  opacity: 0;
  animation: slide-up 1.5s ease forwards;
  animation-delay: 0.9s;
}

/* End----------------- */

/* Product */
.product_content_container {
  margin-bottom: 50px;
}

/* Story */

.story_section_container {
  /* padding: 5% 0% 0% 0%; */
  /* background: #f1faff; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Our Client Part... */

.summary_section_container {
  padding: 2% 0% 0% 0%;
  /* background: #f1faff; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Faqs */

.faq_section_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  
}

.faq_section_container_title {
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 32px */
  letter-spacing: 0.32px;
  text-transform: capitalize;
}
.faq_section_container_title_part {
  color: #3575c1;
  display: contents;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  letter-spacing: 0.32px;
  text-transform: capitalize;
}

.faq_section_accordion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  /* margin-left: 20%; */
}

.view_more {
  color: #000 !important;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: capitalize;
  text-decoration: underline;
  /* text-decoration: none; */
}

.notlisted_faq{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.notlisted_faq_head{
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 32px */
  letter-spacing: 0.32px;
  text-transform: capitalize;
}

.notlisted_faq_content{
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 32px */
  letter-spacing: 0.32px;
  text-transform: capitalize;
}
/* --------- */
.ContactUs_container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.ContactUs_back {
  width: 80%;
  height: auto;
  background: linear-gradient(to bottom, #d8eaf5 65%, #fff 50%);
  display: flex;
  justify-content: center;
}

/* Footer */

.footer_container {
  margin-top: 15%;
  width: 100%;
  height: 60vh;

  bottom: 0;
}

/* responsive for Home Page main  */

@media (max-width: 1183px) {
  .homepage_banner_title {
    width: 50%;
    font-size: 29px;
  }
  .faq_section_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 8%;
  }
  .faq_section_container_title {
    font-size: 36px;
  }

  .faq_section_accordion {
    width: 75%;
    /* margin-left: 20%; */
  }
}

@media (max-width: 1083px) {
  .story_section_container {

    padding: 12% 0% 0% 0%;
    /* background: #f1faff; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homepage_banner_title {
    width: 50%;
    font-size: 24px;
  }
  .homepage_banner_layout_btn{
    width: 86%;
  }
  .homepage_banner_btn {
    width: fit-content;
    text-align: left;
    height: 10%;
  }

  .homepage_banner_content {

    align-items: left !important;
    align-items: left;
    gap: 30px;
  }

  .req_btn {
    display: inline-flex;
    height: auto;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: #7cf169;
    font-family: Inter;
    font-size: 12px;
  }
  .ContactUs_container {
    width: 100%;
    height: 50vh;
    background: linear-gradient(to bottom, #d8eaf5 65%, #fff 50%);
  }

  .ContactUs_back {
    width: 80%;
    height: 100%;
  }
}

@media (max-width: 1020px) {
  .footer_container {
    margin-top: 15%;

    width: 100%;
    /* height: 65vh; */
    bottom: 0;
  }
}
.story_section_container {
  /* padding: 12% 0% 0% 0%; */
  /* background: #f1faff; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 800px) {
  /* Faq Responsive for Mobile */
  .summary_section_container {
    padding: 7% 0% 0% 0%;
    /* background: #f1faff; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .story_section_container {
    padding: 12% 0% 0% 0%;
    /* background: #f1faff; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .faq_section_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    margin: 0% 8%;
  }
  .faq_section_container_title {
    font-size: 16px;
  }
  .faq_section_container_title_part {
    font-size: 16px;
  }
  .faq_section_accordion {
    width: 100%;
  }
  .view_more {
    font-size: 10px;
    text-align: left;
  }
  .notlisted_faq{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }
  
  .notlisted_faq_head{
    font-size: 10px;
  }
  
  .notlisted_faq_content{
    font-size: 10px;
  }
  .homepage_banner_title {
    width: 86%;
    padding: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    text-align: left;
  }
  .homepage_banner_title span {
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
  }
  .homepage_banner_subtitle {
    /* padding-left: 10px; */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    width: 86%;
  }
}
/* Footer */

@media (max-width: 400px) {
  .main_container {
    position: relative;
    width: 100%;
    height: auto;
  }
}
