.summary_container {
  width: 90%;
  display: flex;
  flex-direction: column !important;
  gap: 30px;
  padding: 4% 0%;
  justify-content: center;
  align-items: center;
  background: #f1faff;
}

.summary_container_content {
  display: flex;
  flex-direction: column !important;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.summary_container_title {
  margin-top: 20px;
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 87.5% */
  letter-spacing: 0.32px;
}

.summary_container_title_color {
  display: inline-block;
  color: #3575c1;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0.32px;
}
.summary_container_info {
  width: 65%;
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.18px;
}

.summary_container_image {
  width: 90%;
}
.image_container img {
  width: 142px;
  height: 110px;
  flex-shrink: 0;
}

.image_container {
  display: flex;
  flex-direction: row !important;
  overflow: hidden;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  position: relative;
}

.moving_image {
  width: auto;
  height: 80px;
  animation: slide-left 10s linear infinite;
}

/* Box Images */

.our_client_container_image {
  width: 100%;
  display: flex;
  flex-direction: column !important;
  gap: 45px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.our_client_personal_care {
  width: 75%;
  display: flex;
  flex-direction: column !important;
  /* gap: 35px; */
  justify-content: center;
  background-color: #ffffff;
}
.our_client_container_personal_care_head {
  padding: 2% 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  text-transform: capitalize;
}

.our_client_container_personal_care {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
  background-color: #ffffff;
}

.box_img {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #ffffff;
  background: #f1faff;
  padding: 2% 0;
}
.box_img img {
  width: 100%;
  height: auto;
  flex-shrink: 0;
}

/* END */

@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 1140px) {
  .summary_container_title {
    font-size: 28px;
  }
  .summary_container_info {
    font-size: 24px;
  }
}

@media (max-width: 800px) {
  .summary_container {
    gap: 0px;
    padding: 0px;
    width: 92%;
  }
  .summary_container_content {
    gap: 15px;
    align-items: flex-start;
    padding: 20px 15px 20px 15px;

  }

  .summary_container_title {
    
    font-size: 16px;
    font-weight: 500;
    line-height: 17.831px; /* 111.441% */
    letter-spacing: 0.16px;
    text-align: left;
    margin:0px;
    text-transform: capitalize;
  }
  .summary_container_title_color {
    display: block;
    font-size: 16px;
    font-weight: 800;
    line-height: 17.831px; /* 111.441% */
    letter-spacing: 0.16px;
    text-transform: capitalize;
  }
  .summary_container_info {
    width: 90%;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.12px;
    text-align: left;
  }
  .image_container {
    height: 100px;
  }
  .image_container img {
    width: 47.422px;
    height: 35.009px;
    flex-shrink: 0;
  }

  /* box  */

  .our_client_container_image {
    width: 91.5%;
    display: flex;
    flex-direction: row !important;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
  }
  .our_client_personal_care {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    /* gap: 35px; */
    justify-content: center;
    background-color: #ffffff;
  }
  .our_client_container_personal_care_head {
    width: 100%;
    padding: 2% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1px;
    border-right: 3px solid #ffffff;
  }

  .our_client_container_personal_care {
    width: 100%;
    display: flex;
    flex-direction: column !important;
  }

  .box_img {
    width: 100%;
    border: 2px solid #ffffff;
  }
  .box_img img {
    width: 100%;
    height: auto;
    flex-shrink: 0;
  }
}
