.our_story_container {
  padding: 5% 10%;
  width: 100%;
  background: #f0f0f0;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 2%;
}

/* Top Section */
.our_story_container_top {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  gap: 0px;
  justify-content: center;
  /* height: 50vh; */
}

.top_img_container {
 
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: auto;
  width: 50%;
}

/* Image Poster */

.left_content {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.poster_name {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.3); */
  pointer-events: none;
  align-items: flex-end;
}

.poster_name img {
  position: absolute;
  bottom: 0px;
  right: 0;
}

.poster_name_text {
  position: absolute;
  top: 40px;
  right: 20px;
  width: 40%;
  height: 100%;
  color: #fff;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: flex-start;
  align-items: center;
}
.poster_name_text p {
  display: contents;
  color: #7cf169;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.poster_name_text span {
  /* color: #7cf169; */
  width: 80%;
  position: relative;
  top: 30px;
  font-size: 16px;
  text-align: right;
}

.top_content {
  background-color: #fff;
  width: 40%;
  padding: 0% 4%;
  /* height: 50vh; */
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  /* gap: 30px; */
}
.top_content_layout1 {
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column !important;
  padding: 8% 0%;
  gap: 20px;
}
.top_content_layout {
  display: flex;
  flex-direction: column !important;

  padding: 7% 0%;
  gap: 20px;
}

.top_content_layout_head {
  display: flex;
  flex-direction: row !important;
  gap: 22px;
}
.top_content_layout_head_img {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7cf169;
}
.top_content_layout_head_img img {
  width: 28px;
  height: 28px;
  /* border: 1px solid black; */

  filter: brightness(0) saturate(100%);
}
.top_content_layout_head_title {
  justify-content: center;
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
.top_content_layout_head_title span {
  display: contents;
  color: #111;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: capitalize;
}

.top_content_layout_info {
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}

/* Bottom Section */
.our_story_container_bot {
  width: 100%;
  background: #f0f0f0;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.bot_content {
  width: 37%;
  background: #f0f0f0;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: left;
  gap: 25px;
}
.product_content_title {
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px; /* 125% */
}
.product_content_title span {
  display: contents;
  color: #3575c1;
  font-family: Manrope, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
}
.bot_content_title {
  width: 100;
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px; /* 125% */
}
.our_story_main_content_title {
  width: 100;
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px; /* 125% */
}

.bot_content_subtitle {
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 150% */
  letter-spacing: 0.16px;
}
.bot_content_btn {
  display: inline-flex;
  width: fit-content;
  height: auto;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  background: #7cf169;
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}
.bot_content_title span {
  display: contents;
  color: #3575c1;
  font-family: Manrope, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
}
.our_story_main_content_title span{
  display: contents;
  color: #3575c1;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
}

.bot_card {
  width: 45%;
  background: #f0f0f0;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: left;
}
.bot_card_layout {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
}
.bot_card_layout2 {
  width: 100%;
  padding: 8% 0%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly;
  background: #f2ffed;
  border-top: 4px solid #fff;
}

.bot_card_content1 {
  /* border: 1px solid black; */
  padding: 8% 4%;
  width: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  border-right: 4px solid #fff;
  border-bottom: 4px solid #fff;
  background: #f2ffed;
}
.bot_card_content2 {
  /* border: 1px solid black; */
  padding: 8% 4%;
  width: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  border-left: 4px solid #fff;
  border-bottom: 4px solid #fff;
  background: #f2ffed;
}
.bot_card_content3 {
  /* border: 1px solid black; */
  padding: 8% 4%;
  width: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  border-top: 4px solid #fff;
  border-right: 4px solid #fff;
  background: #f2ffed;
}
.bot_card_content4 {
  /* border: 1px solid black; */
  padding: 8% 4%;
  width: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  border-top: 4px solid #fff;
  border-left: 4px solid #fff;
  background: #f2ffed;
}
.bot_card_value {
  color: #002d62;
  font-family: Taviraj, serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bot_card_value span {
  display: contents;
  color: #002d62;
  font-family: Taviraj, serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  vertical-align: super;
  
}
.part3_img{
  width: 60px;
  height: auto;
}
.bot_card_title{
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 800px) {
  .top_content_layout_head {
    display: flex;
    flex-direction: column !important;
   gap: 8px;
  }
  .our_story_container {
    padding: 25px 15px 10%;
        width: 100%;
        background: #f0f0f0;
        display: flex;
        align-self: center;
        justify-content: center;
        text-align: center;
        align-items: center;
        gap: 15px;
        margin: 0;
  }

  .our_story_container_top {
    flex-direction: column-reverse !important;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .top_img_container {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 428px;
    width: 95%;
  }
  .top_content {
    background-color: #fff;
    width: auto;
    padding: 10% 10%;
    /* height: 50vh; */
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    /* gap: 30px; */
  }
  .top_content_layout1 {
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: column !important;
    padding: 0% 0%;
    gap: 20px;
    padding-bottom: 7%;

    text-align: left;
  }
  .top_content_layout {
    display: flex;
    flex-direction: column !important;
    text-align: left;
    padding: 7% 0%;
    gap: 20px;
  }

  .top_content_layout_info {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.12px;
  }
  .our_story_container_bot {
    width: 91%;
    background: #f0f0f0;
    display: flex;
    flex-direction: column !important;
    /* justify-content: center; */
    /* align-items: center; */
    text-align: left;
    gap: 30px;
    margin-top: 35px;
  }

  .bot_content {
    width: 100%;
    background: #f0f0f0;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: left;
    gap: 15px;
  }

  .bot_content_title {
    width: 100%;
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    text-align: left;
  }
  .our_story_main_content_title {
    width: 91%;
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    text-align: left;
  }

  .bot_content_subtitle {
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.12px;
  }
  .bot_content_btn {
    display: inline-flex;
    width: fit-content;
    height: auto;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    background: #7cf169;
    color: #000;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
  }
  .bot_content_title span {
    display: contents;
    color: #3575c1;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
  }
  .our_story_main_content_title span {
    display: block;
    color: #3575c1;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
  }

  .bot_card {
    width: 100%;
    background: #f0f0f0;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: left;
  }
  .bot_card_layout {
    width: 100%;
    display: flex;
    flex-direction: row !important;
    justify-content: center;
  }
  .bot_card_layout2 {
    width: 100%;
    padding: 8% 0%;
    display: flex;
    flex-direction: row !important;
    justify-content: space-evenly;
    background: #f2ffed;
    border-top: 4px solid #f0f0f0;
  }
  .bot_card_content1 {
    /* border: 1px solid black; */
    padding: 2% 2%;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    border-right: 4px solid #f0f0f0;
    border-bottom: 4px solid #f0f0f0;
    background: #f2ffed;
  }
  .bot_card_content2 {
    /* border: 1px solid black; */
    padding: 2% 2%;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    border-left: 4px solid #f0f0f0;
    border-bottom: 4px solid #f0f0f0;
    background: #f2ffed;
  }
  .bot_card_content3 {
    /* border: 1px solid black; */
    padding: 8% 4%;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    border-top: 4px solid #f0f0f0;
    border-right: 4px solid #f0f0f0;
    background: #f2ffed;
  }
  .bot_card_content4 {
    /* border: 1px solid black; */
    padding: 8% 4%;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    border-top: 4px solid #f0f0f0;
    border-left: 4px solid #f0f0f0;
    background: #f2ffed;
  }
  .bot_card_value {
    color: #002d62;
    font-family: Taviraj, serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .bot_card_value span {
    display: contents;
    color: #002d62;
    font-family: Taviraj, serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .left_content {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .poster_name {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.3); */
    pointer-events: none;
    align-items: flex-end;
  }

  .poster_name img {
    width: 40%;
    position: absolute;
    bottom: 0px;
    right: 0;
  }

  .poster_name_text {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 45%;
    height: 100%;
    color: #fff;
    font-family: Manrope, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    justify-content: flex-start;
    text-align: left;
  }
  .poster_name_text p {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .bot_card_title{
    font-size: 12px;
    font-weight: 600;
  }
  .part3_img{
    width: 45.5px;
    height: auto;
  }
  .poster_name_text span {
    width: 90%;
    position: relative;
    top: 15px;
    font-size: 10px;
    text-align: right;
  }
}
