.product_section_container {
  padding: 7% 0 0 0%;
  width: 100%;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  gap: 60px;
}

.section_head{
  display: flex;
  flex-direction: column !important;
  text-align: center;
  /* justify-content: center; */
  gap: 22px;
}
.product_section_head {
  width: 100%;
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  text-transform: capitalize;
}
.product_section_subhead {
  width: 100%;
  color: #858585;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  text-transform: capitalize;
}

.product_section_head span {
  display: contents;
  color: #3575c1;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.32px;
  text-transform: capitalize;
}

.product_section_content {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row !important;
  gap: 3%;
  flex-wrap: wrap;
  justify-content: center;
}

.card_layout {
  margin-bottom: 3%;
  display: flex;
  flex-direction: column !important;
  width: 18%;
  height: auto;
}


.card_layout_icon {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding: 15% 0;
  gap: 30px;
  background: #F0F0F0;
  
}
.card_layout_icon_hovered{
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding: 15% 0;
  gap: 30px;
  background: #002d62;
 
}


.card_icon {
  width: 68px;
  height: 68px;
  flex-shrink: 0;
}
.card_icon_hovered{
  width: 68px;
  height: 68px;
  flex-shrink: 0;
}

.card_title {

  color: black;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.card_title b {
  display: contents;
  font-weight: 600 !important;
}
.card_title_hovered{
  color: #fff;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.card_title_hovered b {
  display: contents;
  font-weight: 600 !important;
}

.card_layout_btn {
  padding: 6% 0;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  border-top: 2px solid white;
  cursor: pointer;
  background-color: #F1FAFF;
  font-size: 20px;
  color: #002d62;
  
}

.card_layout_btn_hovered{
  padding: 6% 0;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  border-top: 2px solid white;
  cursor: pointer;
  background-color: chartreuse;
  font-size: 20px;
  color: #002d62;
}

/* Modal Data */

.Modal_container {
  background: #f0f0f0;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  width: 40%;
  flex-direction: column !important;
  box-shadow: 24;
  padding: 1% 2% 3% 2%;
}

.Modal_head {
  display: flex;
  flex-direction: row !important;
  gap: 30px;
  border-bottom: 2px solid #002d62;
  justify-content: space-between;
  padding: 2% 0%;
}

.Modal_title {
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}

.Modal_title b {
  display: contents;
}
.modal_close {
  color: #647491;
  display: flex;
  width: 27px;
  height: 27px;
  padding: 8.25px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  cursor: pointer;
}

.Modal_content {
  padding: 6% 0;
  display: flex;
  flex-direction: column !important;
  gap: 50px;
}

.Modal_content_layout {
  display: flex;
  flex-direction: column !important;
  gap: 5px;
}

.Modal_content_info {
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.Modal_content_points {
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}
.Modal_content_info b {
  display: contents;
}

/* contact btn */
.bot_content_btn {
  display: inline-flex;
  width: fit-content;
  height: auto;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  background: #7cf169;
  color: #000;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}

@media (max-width: 800px) {
  .product_section_container {
    padding: 12% 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
    gap: 30px;
  }

  .section_head{
    display: flex;
    flex-direction: column !important;
    text-align: left;
    justify-content: flex-start;
    padding: 0px 30px;
  }

  .product_section_head {
    width: 100%;
    color: #000;
    text-align: left;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: capitalize;
  }

  .product_section_head span {
    display: contents;
    color: #3575c1;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: capitalize;
  }
  .product_section_subhead {
    width: 100%;
    color: #858585;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: capitalize;
  }

  .product_section_content {
    width: 92.3%;
    align-items: center;

    display: flex;
    flex-direction: row !important;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0px 15px;
  }

  .card_layout {
    margin-bottom: 3%;
    display: flex;
    flex-direction: column !important;
    width: 30%;
    height: auto;
  }

  .card_layout_icon {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    padding: 15% 0;
    gap: 10px;
    background: #F0F0F0;
  }
  .card_layout_icon_hovered{
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    padding: 15% 0;
    gap: 10px;
    background: #002d62;
   
    
  }
  .card_icon {
    width: 28.617px;
    height: 29.733px;
    flex-shrink: 0;
  }
 
  .card_icon_hovered{
    width: 28.617px;
    height: 29.733px;
    flex-shrink: 0;
  }
  .card_title {
    color: black;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
  .card_title_hovered{
    color: #fff;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  .card_layout_btn {
    padding: 2% 0;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    border-top: 2px solid white;
    cursor: pointer;
    background-color: #F1FAFF;
    font-size: 12px;
    color: #002d62;
  }
  .card_layout_btn_hovered{
    padding: 2% 0;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    border-top: 2px solid white;
    cursor: pointer;
    background-color: chartreuse;
    font-size: 12px;
    color: #002d62;
  }

  /* Card----- */

  .Modal_container {
    background: #f0f0f0;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    display: flex;
    width: 85%;
    flex-direction: column !important;
    box-shadow: 24;
    padding: 1% 2% 3% 2%;
  }

  .Modal_head {
    display: flex;
    flex-direction: row !important;
    gap: 30px;
    border-bottom: 2px solid #002d62;
    justify-content: space-between;
    padding: 2% 4%;
  }

  .Modal_title {
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: capitalize;
  }

  .Modal_title b {
    display: contents;
  }
  .modal_close {
    color: #647491;
    display: flex;
    width: 15px;
    height: 15px;
    padding: 8.25px;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    cursor: pointer;
  }

  .Modal_content {
    padding: 6% 0;
    display: flex;
    flex-direction: column !important;
    gap: 10px;
  }

  .Modal_content_layout {
    display: flex;
    flex-direction: column !important;
    gap: 5px;
    padding: 0 4%;
  }

  .Modal_content_info {
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12px;
  }

  .Modal_content_points {
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.12px;
  }
  .Modal_content_info b {
    display: contents;
  }

  /* contact btn */
  .bot_content_btn {
    display: inline-flex;
    width: 30%;
    height: 30px;
    padding: 4px 15px;
    margin-left: 4%;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    background: #7cf169;
    color: #000;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
  }
}
